<script setup lang="ts">
import { useUserStore } from "~/stores/user";

const { user } = useUserStore();
const { theme, isVemqda, isClubeConecta, isClaroAfiliados } = useTheme();
const isOpen = ref(false);
const activeRetailChainAndPosStore = useActiveRetailChainAndPosStore();
const { isManagerLP } = useUserStore();
const { isChatHasNewNotifications, isWhatsAppModalOpen } =
  storeToRefs(useWhatsappChatV1());

const whatsAppStore = useWhatsappChatV1();
const isAccountSideSliderOpen = ref(false);

const handleWhatsAppModalClose = () => whatsAppStore.setActiveChat({});

const { toggle } = useSiderbarToggle();
</script>

<template>
  <ClientOnly>
    <nav>
      <VMainSideSlider :is-open="isOpen" @close="isOpen = false" />
      <div class="flex flex-row max-h-20 gap-4 justify-between items-center">
        <div class="hidden md:flex flex-row gap-4 ml-4 py-5 w-[240px]">
          <LogoVemqda v-if="isVemqda && !isManagerLP" class="cursor-pointer" />
          <LogoClubeConecta
            v-if="isClubeConecta && !isManagerLP"
            class="cursor-pointer pl-20"
          />
          <LogoClaroAfiliados
            v-if="isClaroAfiliados && !isManagerLP"
            class="cursor-pointer"
          />
          <LogoDiogoComm v-if="isManagerLP" />

          <div class="hidden md:flex">
            <UButton
              variant="ghost"
              icon="i-heroicons-bars-3"
              class="text-2xl text-basicText cursor-pointer"
              @click="toggle"
            />
          </div>
        </div>

        <div class="md:hidden ml-4 flex flex-col justify-center items-center">
          <UIcon
            name="i-heroicons-bars-3"
            class="text-2xl text-basicText cursor-pointer"
            @click="isOpen = true"
          />
        </div>

        <VRetailChainPosNavbar />

        <div class="flex-col justify-center items-center hidden md:flex">
          <NuxtLink :to="`/${theme}/dashboard/central-de-ajuda`">
            <div
              class="flex flex-row gap-2 cursor-pointer hover:bg-zinc-200 p-2 rounded-full transition-all duration-200 items-center"
            >
              <span class="text-basicText">Ajuda</span>
              <UIcon
                name="i-heroicons-question-mark-circle"
                class="text-basicText w-5 h-5"
              />
            </div>
          </NuxtLink>
        </div>

        <div>
          <UChip
            v-if="activeRetailChainAndPosStore.canShowChat"
            size="xl"
            :show="isChatHasNewNotifications"
          >
            <UButton
              variant="ghost"
              :disabled="!activeRetailChainAndPosStore?.pos"
              @click="isWhatsAppModalOpen = true"
            >
              <UIcon name="i-logos-whatsapp-icon" dynamic class="size-6" />
            </UButton>
          </UChip>

          <WhatsAppQrCodeModal />
          <UModal
            v-model="isWhatsAppModalOpen"
            :ui="{
              width: 'md:2xl',
            }"
            @close="handleWhatsAppModalClose"
          >
            <div class="p-2">
              <LazyWhatsAppChat />
            </div>
          </UModal>
        </div>

        <VUserAvatar
          img-src="https://gravatar.com/avatar/7d611dc9c0363292139981aefe74c3dc?s=400&d=robohash&r=x"
          :username="user?.name ? user?.name : ''"
          @click="isAccountSideSliderOpen = true"
        />

        <VAccountSideSlider
          :is-open="isAccountSideSliderOpen"
          @close="isAccountSideSliderOpen = false"
        />
      </div>
    </nav>
  </ClientOnly>
</template>
